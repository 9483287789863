<template>
  <div class="row">
    <div
      id="dashboard-content-container"
      class="col-sm-12"
    >
      <div class="box box-default box-solid">
        <div class="box-body ">
          <h1 style="text-align: center">
            {{$t('dashboard.welcomeText')}}
          </h1>
        </div>
        <template
          v-for="maintenanceInfo in maintenanceInfoToShow"
        >
          <info-box
            v-if="maintenanceInfoToShow.length !== 0 && parseInt(maintenanceInfo.infoType) === 2"
            :key="maintenanceInfo.id"
            :info-type="types[maintenanceInfo.infoType]"
            style="text-align: center;"
          >
            <p style="white-space: pre;">
              {{ maintenanceInfo.infoText }}
            </p>
          </info-box>
        </template>

        <section class="content box box-default box-solid box-component">
          <div class="">
            <p>{{ currentDate }}</p>
            <i18n  v-if="$auth.isAllowed({service: 'core', action: 'get_user_guides'})" path="dashboard.instructionBasic.term" tag="p">
              <a @click="() => getInstruction(null,`Instrukcja_Obsługi_programu_ZORDON_${$store.state.base.user.contractor}`)">{{ $t('dashboard.instructionBasic.tos') }}</a>
            </i18n>
            <i18n v-if="$auth.isAllowed({service: 'core', action: 'get_user_guides-1'})" path="dashboard.instructionFokusGeneral.term" tag="p">
              <a @click="() => getInstruction(1, `FOKUS_w_ZORDON_instrukcja_ogólna.pdf`)">{{ $t('dashboard.instructionFokusGeneral.tos') }}</a>
            </i18n>
            <i18n v-if="$auth.isAllowed({service: 'core', action: 'get_user_guides-2'})" path="dashboard.instructionFokusExtended.term" tag="p">
              <a @click="() => getInstruction(2, `FOKUS_instrukcja_szczegółowa.pdf`)">{{ $t('dashboard.instructionFokusExtended.tos') }}</a>
            </i18n>
            <p v-if="$auth.isAllowed({service: 'core', action: 'get_user_guides-3'})">Instrukcja obsługi systemu Zordon dla pracowników UNIQA znajduje się <a @click="() => getInstruction(3, `Instrukcja_3.pdf`)">tutaj</a></p>
            <p v-if="$auth.isAllowed({service: 'core', action: 'get_user_guides-4'})">Dokument dla rzeczoznawców BLS <a @click="() => getInstruction(4, `Instrukcja_4.pdf`)">tutaj</a></p>
            <p><span v-if="fokusVersion">{{ $t('dashboard.fokus.lastUpdate')}} {{ fokusVersionLocalised }}</span></p>
            <p>
              <i18n v-if="fokusContent" path="dashboard.fokus.changelog.term" tag="span">
                <a @click="getFokusChangelogFile">{{ $t('dashboard.fokus.changelog.tos') }}</a>
              </i18n>
            </p>
            <br>
            <i18n path="dashboard.supportMessage.term" tag="p">
              <a :href="`mailto:${$t('dashboard.supportMessage.tos')}`">{{ $t('dashboard.supportMessage.tos') }}</a>
            </i18n>
            <i18n path="dashboard.feedbackMessage.term" tag="p">
              <a :href="`mailto:${$t('dashboard.feedbackMessage.tos')}`">{{ $t('dashboard.feedbackMessage.tos') }}</a>
            </i18n>
            <!--            <p>Jeśli masz uwagi lub sugestię dotyczące działania systemu prześlij je nam na adres <a href="mailto:zordon-uwagi@zordon.pl">zordon-uwagi@zordon.pl</a></p>-->
          </div>
        </section>
        <template
          v-for="maintenanceInfo in maintenanceInfoToShow"
        >
          <info-box
            v-if="maintenanceInfoToShow.length !== 0 && parseInt(maintenanceInfo.infoType) === 1"
            :key="maintenanceInfo.id"
            :info-type="types[maintenanceInfo.infoType]"
            style="text-align: center;"
          >
            <p style="white-space: pre;">
              {{ maintenanceInfo.infoText }}
            </p>
          </info-box>
        </template>
      </div>
      <footer
        class="login-footer"
      >
        <div>
          <div>
            <span><a href="http://www.crls.pl/rodo/">Polityka prywatności </a></span>
            <span><a href="http://www.crls.pl/cookies/">Polityka cookies </a></span>
          </div>
          <div><span class="copyright">&copy; 2017 – 2021 ZORDON</span></div>
          <div>
            <span><a
              href="https://projekty.zordon.pl/"
              style="cursor: pointer;"
            >
              Projekty
            </a>
            </span>
            <span><router-link :to="{name: 'system_requirements'}"><a>Wymagania systemowe </a></router-link></span>
            <span><a href="http://www.crls.pl/kontakt.html">Kontakt</a></span>
          </div>
        </div>
      </footer>
    </div>
    <simple-modal
      title="Lista ostatnich zmian w aplikacji FOKUS"
      :subject="[[],'']"
      :show="modalOpen"
      @close="modalOpen = false"
    >
      <pre>{{ fokusContent }}</pre>
    </simple-modal>
  </div>
</template>

<script>
import moment from 'moment'
import api from '../../api'
import 'moment/locale/pl'
import ErrorNotify from '../share/mixins/ErrorNotify'
import DownloadBlobFromBase64 from '../share/mixins/DownloadBlobFromBase64'
import InfoBox from '../share/InfoText/InfoBox'
import SimpleModal from '../share/modal/BaseSimpleModal'

export default {
  components: {
    SimpleModal,
    InfoBox
  },
  mixins: [
    ErrorNotify,
    DownloadBlobFromBase64
  ],
  data () {
    return {
      userGuide: [],
      dashboardContainer: null,
      maintenanceInfos: [],
      fokusContent: '',
      fokusChangeLog: '',
      fokusVersion: '',
      modalOpen: false,
      types: {
        1: 'primary',
        2: 'warning'
      }
    }
  },
  computed: {
    fokusVersionLocalised () {
      return this.fokusVersion.locale(this.$i18n.locale).format('DD MMMM YYYY')
    },
    currentDate () {
      moment.locale('pl')
      let date = new Date()
      let localMoment = moment(date).locale(this.$i18n.locale).format('dddd, D MMMM YYYY')
      return this.$t(`dashboard.currentDate`, {date: localMoment})
    },
    maintenanceInfoToShow () {
      return this.maintenanceInfos.filter(info => info.roles.some(role => this.$store.state.base.user.roles.includes(role)))
    }
  },
  mounted () {
    this.getFokusInfo()
    this.getMaintenanceInfo()
  },

  methods: {
    async getFokusInfo() {
      try {
        let response = await fetch(process.env.VUE_APP_FOKUS_CHANGELOG_URL)
        let text = await response.text()
        this.fokusContent = text
        this.fokusVersion = moment(text.split('\n')[0], 'DD MMMM YYYY')
        // this.fokusVersion = text.split('\n')[0]
        this.fokusChangeLog = text.split('\n').filter((el, index) => index > 0).join('<br/>')
      } catch (e) {
        console.error(e)
      }

    },
    getInstruction (id, filename) {
      api.request('core', 'get', `contractors/user-guide${id ? '-' + id : ''}`)
        .then((response) => {
          this.userGuide = response.data
          let file = response.data
          this.downloadBlob(this.base64toBlob(file.value, file.mimeType), filename ? filename : 'Instrukcja obsługi')
        })
        .catch((e) => {
          if (e.response && e.response.data.error && e.response.data.error.message.includes('No such file')) {
            this.errorNotify(e, 'error', 'Wystąpił błąd', 'Brak odpowiedniej instrukcji')
          } else {
            this.$notify({
              type: 'error',
              title: 'Wystąpił błąd',
              text: 'Nie można pobrać instrukcji'
            })
          }
        })
    },
    getFokusChangelogFile () {
      //let blob = new Blob([this.fokusContent], {type : 'text/html'});
      //this.downloadBlob(blob, `fokusChangelog.txt`)
      this.modalOpen = true;
    },
    getMaintenanceInfo () {
      this.maintenanceInfos = []
      api.request('core', 'get', `maintenance-info`)
        .then((response) => {
          this.maintenanceInfos = response.data
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }
}
</script>
<style scoped>
pre {
  white-space: pre-wrap;
}
.current-date {
  position: relative;
  left: 92%;
  width: 9%;
}
.box-component {
  text-align: center;
}
a {
  cursor: pointer;
  font-weight: bold;
}
</style>
