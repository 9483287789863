import api from '@/api/index.js'
import { setupTotalCount } from '../setupTotalCount'
import _ from 'lodash'

export default {
  data() {
    return {
      blsOpenAdvencedServices: ['intbls', 'intgen', 'intvig', 'intvhs'],
      blsOpenAdvencedAcl: ['get_open_task_statistics', 'frontend_acl_get_open_statistics_plus'],
      blsOpenAdvancedTask: {
        title: 'Otwarte zlecenia',
        total: '',
        data: {
          x: 'x',
          columns: [],
          groups: []
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60
            }
          }
        }
      }
    }
  },
  methods: {
    getAllowedBlsOpenAdvancedServices(services) {
      return this.$auth.getAllowed({ services: services, actions: this.blsOpenAdvencedAcl, allowOneOfActions: true })
    },
    getOpenAdvancedStatisticRequestPromises (services) {
      return services.map(service => api.request(service, 'get', `statistics/tasks/open?user=${this.$store.state.base.user.uuid}`))
    },
    loadBlsOpenAdvancedTaskStatistic (services) {
      this.processBlsOpenAdvancedTaskStatistic(services)
      return
    },
    async processBlsOpenAdvancedTaskStatistic (services) {
      services = this.getAllowedBlsOpenAdvancedServices(services)

      if (!_.isEmpty(services)) {
        try {
          let requests = this.getOpenAdvancedStatisticRequestPromises(services)
          let responses = await this.getResponses(requests)
          let responseData = this.filterBy(responses, 'data')
          let chartData = this.getInitialChartData(this.workflows, this.trans)

          this.prepareChart(responseData, chartData, this.workflows, this.contractorApps)
          this.addOpenAdvanvcedTaskToCharts(chartData)
          this.addOpenAdvanvcedTaskToRefChart(!_.isEmpty(responseData), chartData)
        } catch (error) {
          console.error(error)
        }
      }
      return
    },
    addOpenAdvanvcedTaskToCharts (chartData) {
      this.blsOpenAdvancedTask.total = setupTotalCount(chartData.columns);
      this.blsOpenAdvancedTask.data.columns.push(chartData.columns.categories)
      this.blsOpenAdvancedTask.data.columns.push(chartData.columns.main_vehicle)
      this.blsOpenAdvancedTask.data.columns.push(chartData.columns.main_property)
    },
    addOpenAdvanvcedTaskToRefChart(addToRef, chartData) {
      if (addToRef) {
        this.$refs.blsOpenAdvancedTask.chart.groups([chartData.groups])
      }
    }
  }
}
