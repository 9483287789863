export default function (service, appraisal) {
  let services = ['extgen', 'extavi', 'demofokus', 'extgsl', 'extacn', 'extgcz', 'extuni']
  if ((services.indexOf(service) > -1) && appraisal === false) {
    return {
      event: { name: `${service}:main:sendFocusSms` },
      icon: 'send',
      label: 'menu.main.taskDetails.fokussms',
      acl: { service: service, action: 'post_focus_sms' }
    }
  } else if (['demo'].includes(service) && appraisal === true) {
    return {
      event: { name: `${service}:appraisal:sendFocusSms` },
      icon: 'send',
      label: 'menu.main.taskDetails.fokussms',
      acl: { service: service, action: 'post_focus_sms' }
    }
  } else {
    return {
      event: { name: `invisibleButton` },
      icon: 'send',
      label: 'invisibleButton',
      acl: { service: service, action: 'invisibleButton' }
    }
  }
}
